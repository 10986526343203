<template>
    <div>
        <div class="container" style="background:transparent;color:white;width:75%" v-show="ongoingGames.games.length > 0">
            <h3 class="p-3 text-center">Ongoing {{getGameNameFromType(gameType)}} games you could join </h3>
            <div class="overflow-auto">
                <b-pagination pills align="center"
                v-model="currentPage"
                :total-rows="maxRows"
                :per-page="perPage"
                first-text="⏮"
                prev-text="⏪"
                next-text="⏩"
                last-text="⏭"
                aria-controls="mygames"
                v-show="ongoingGames.games.length > perPage"></b-pagination>
                <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                <b-table striped hover small
                id="mygames"
                :items="ongoingGames.games"
                :per-page="perPage"
                :current-page="currentPage"
                head-variant="dark"
                style="color:white"
                :fields="fields"
                :table-variant="tableVariant"
            >
                    <template #cell(gameId)="data">
                        <b-button pill variant="primary" size="sm" @click="doSelfInvite(data.value)">
                            Join
                        </b-button>
                    </template>
                    <template #cell()="data">
                        {{ data.value }}
                    </template>
                </b-table>
            </div>
        </div> 
        <b-jumbotron v-if="ongoingGames.games.length == 0" style="color:white;background:transparent;width:75%" header="Lonely here!!!" lead="Be a sport, start a game and invite folks...">
        </b-jumbotron>
    </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';

import {
		GameType,
        GameStates
	} from '@/grpcservices/Game_pb';

export default {
    name: "gamelist",
    props: {
        gameType: {
            Type: GameType
        },
        showInvitedGames:{
            Type: Boolean,
            default: false
        },
    },
    computed:{
        ...mapState(["userinfo"]),
    },
    data() {
		return {
            ongoingGames: {
				games: []
		    }, 
            currentPage:0,
            maxRows:100,
            perPage:10,
            GameStates: GameStates,
            GameType: GameType,
            fields: [
                // A column that needs custom formatting
                { key: 'gameId', label: 'Game' },
                { key:'hostedBy', label: 'Host'}, 
                'gameName',
                'gameState',
                { key:'participantsInRoom', label:'Participants'},
            ],
            tableVariants: [
            'primary',
            'secondary',
            'info',
            'danger',
            'warning',
            'success',
            'light',
            'dark'
            ],
        }
    },
    async mounted() {
        const that = this;
        that.getGames();    
    },  
    methods:{
        ...mapActions(["getActiveGames","SelfInvite","getMyOngoingGames"]),
        async getGames(){
            try{
            const that = this;
                var results = that.showInvitedGames ? 
                        await that.getMyOngoingGames({ participantState: 'PLAYING'}) :
                        await that.getActiveGames({gameType:that.gameType});

                that.ongoingGames.games.push(...results.games);        
                console.log("games: ",that.ongoingGames);
           	} catch (error) {
                   console.log("Gamelist::ERROR getting active games...",error);
            }
        },
        getGameNameFromType(gameType)
        {
            return Object.keys(this.GameType).find(
                    key => this.GameType[key] === gameType
                );
        },
        getGameState(gameState){
            return Object.keys(this.GameStates).find(
                    key => this.GameStates[key] === gameState
                );
        },
        async doSelfInvite(gameID){
            console.log("doSelfInvite ",gameID);
            const that = this;
            try{
                await that.SelfInvite(gameID);
				await that.$router.push({ name: 'play', params: { id: gameID } });
            }catch(error){
                that.notifyError(`Join error: ${error.message}`);
            }
        }
    },
};
</script>
<style scoped>
	div.jumbotron{
		padding:0px;
	}
</style>
