 <template>
	<div class="home">
		<div style="position: relative; float: right;font-family: Arial, Helvetica, sans-serif;
                font-size:24px;font-weight:bold;"> <a target="_blank" href="/howto/"> Help </a></div>
		<div class="content">
			<div class="logo">
				<img src="../assets/images/home-logo.png" alt="logo" />
			</div>
			<div class="card-vactor">
				<img src="../assets/images/home-cards.png" alt="card" style="width:40%"/>
			</div>
			<div class="welcome-content">
				<center>
					<h1 style="color:white;font-weight:bold;">{{userinfo.userDisplayName}}</h1>
					<p>
						Welcome to ZoomGameZ <br/>
						You could join and continue to play the game you are invited to.
					<p v-if="canStartGame">
						Or start a new game.
					</p>
				</center>
			</div>
			<div class="button-group">
				<router-link v-if="canCreateGame" class="game-button" to="/create-room">{{$t('CREATEROOM')}}</router-link>

				<b-dropdown v-if="allGameDefs.length > 0 && isuser_admin" id="dropdown-2" class="button-dropdown" :text="$t('EDITGAME')" >
					<template v-for="gameDef in allGameDefs">
						<b-dropdown-item v-if="canEditGame(gameDef)" :key="gameDef.id" 
											@click="$router.push({ name: 'create-room', params: {id:gameDef.id}})">
							{{gameDef.name}}
						</b-dropdown-item>
					</template>
				</b-dropdown>

				<b-dropdown  v-if="allGameDefs.length > 0" 
								id="dropdown-3" class="button-dropdown" :text="$t('STARTGAME')">
					<template v-for="gameDef in allGameDefs">
						<b-dropdown-item v-if="canStartGame(gameDef)" :key="gameDef.id" @click="createGameFromDef(gameDef.id)">
							{{gameDef.name}}
						</b-dropdown-item>
					</template>
				</b-dropdown>

			</div>
		</div>
		<div class="content" style="width:100%;top:30px;position:relative">
			<center>
				<h3  v-if="isuser_admin" style="color:white;font-weight:bold;">
					LOOK WHO ELSE IS PLAYING AND JOIN THEM
				</h3>
				<b-card no-body style="width:75%;background:transparent;">
					<b-tabs content-class="mt-3" pills card lazy align="center" style="margin-top:0">
						<b-tab title="Invited / Ongoing Games">
							<b-card-body><gameslist showInvitedGames="true"/></b-card-body>
						</b-tab>
						<b-tab  v-if="isuser_admin" title="Rummy">
							<b-card-body><gameslist :gameType="GameType.RUMMY"/></b-card-body>
						</b-tab>
						<b-tab  v-if="isuser_admin" title="TeenPatti">
							<b-card-body><gameslist :gameType="GameType.TEENPATTI"/></b-card-body>
						</b-tab>
						<b-tab  v-if="isuser_admin" title="Poker">
							<b-card-body><gameslist :gameType="GameType.POKER"/>	</b-card-body>
						</b-tab>
					</b-tabs>
				</b-card>
			</center>
		</div>
		<div class="auth-footer"  style="width:80%;top:30px;position:relative">
			<center>
				<a target="_blank" href="/privacy">Privacy</a> <b>|</b>
				<a target="_blank" href="/terms">Terms of use</a> <b>|</b>
				<a target="_blanK" HREF="/howto/">How to</a>
			</center>
		</div>
	</div>
</template>
<script>
	import { mapGetters, mapState, mapActions } from "vuex";
	import gameslist from '@/components/home/gameslist';
	import DEBUG from "@/common/DEBUG";
	import _ from 'lodash';
	import {
		GameType,
	} from '@/grpcservices/Game_pb';

	export default {
		name: 'home',

		computed: {
			...mapGetters([
				'isuser_admin',
				'isuser_gamecreator',
				'isuser_gamehost',
			]),
			...mapState(["userinfo", "allGameDefs"]),

			
			canCreateGame() {
				return true; // (this.isuser_admin || this.isuser_gamecreator);
			},
			
			currentGame() {
				return this.myjoinedgames.games?.length ? this.myjoinedgames.games[0] : null
			}
		},
		data() {
			return {
				myjoinedgames: {
					games: []
				},
				GameType: GameType,
			};
		},
		components:{
			gameslist,	
		},
		async mounted() {
			const that = this;
			try {
				console.log('mounting home...');
				that.doGetMyJoinedGames();
			} catch (error) {
				console.log('looks like stale token...');
				that.$router.push("logout");
			}
		},

		methods: {
			...mapActions(["getGameDefinitions", "getPublishedGameDefinitions", "createGame", "getMeAsParticipantGames","getMyOngoingGames"]),

			canEditGame(game){
				var canEdit = 
						(this.isuser_admin && 
						(this.userinfo.id == game.ownerId || game.isPublished))
			//	console.log("can ", this.userinfo.id," isadmin:",this.isuser_admin," edit game with owner : ",game.ownerId," & published:",game.isPublished," : ",canEdit)
				return canEdit; // return based on ownerId
			},
			canStartGame(game) {
				//console.log("can ", this.userinfo.id," start game : ",game)
 				return  ( this.userinfo.id == game.ownerId || game.isPublished); // enhance to check for ownerID
 			},
			getGameHost(game)
			{
				var hostName = "Admin";
				game.participantsList.forEach(participant => {
					if(participant.id == game.housePlayerId){
					hostName = participant.email;
					}
				});
				return hostName;
			},
			async doGetGameDefs() {
				const that = this
				try {
					that.error = null;
					await that.getGameDefinitions({ friendly: true });
				} catch (error) {
					console.log("Home::doGetGameDefs ERROR ",error);
					that.error = JSON.stringify(error)
					that.$router.push("logout");
				}
			},

			async doGetMyJoinedGames() {
				const that = this
				try {
					that.error = null;
					that.doGetGameDefs()
					const filter = { participantState: 'PLAYING'};
					const results = await that.getMyOngoingGames(filter);
					console.log('doGetMyJoinedGames - RESULTS - ', results)
					that.myjoinedgames.games.push(...results.games);
				} catch (error) {
					console.log("Home::doGetMyJoinedGames ERROR ",error);
					that.error = JSON.stringify(error)
					that.$router.push("logout");
				}
			},

			async createGameFromDef(gameDefId) {
				const that = this;
				try {
					console.log("CREATING A NEW GAME FOR GAMEDEF - ", gameDefId);
					const game = await that.createGame(gameDefId);

					console.log("CREATED A NEW GAME - ", game);
					await that.$router.push({ name: 'play', params: { id: game.id } });
					await that.notifySuccess(`game created`);
				} catch (error) {
					console.log("ERROR in crete gameea game - %o", error);
					that.notifyError(
						`Unable to save game due to an error - ${error.message}`
					);
				}
			},

			async joinOngoingGame() {
				if (this.currentGame) {
					await this.$router.push({ name: 'play', params: { id: this.currentGame.id } });
				}
			}
		},
	}
</script>
<style scoped>
	div.card-body{
		padding:0px;
	}
	div.my-3{
		margin: top 0px !important;
	}
</style>